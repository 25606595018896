import { Icon, IconProps } from "@chakra-ui/react";
import React, { FC } from "react";

const MailIcon: FC<IconProps> = ({ ...other }) => (
  <Icon
    width="15px"
    height="13px"
    viewBox="0 0 15 13"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="1.25"
    data-testid="mail"
    {...other}
  >
    <path d="M2.33333 1H13C13.7333 1 14.3333 1.6 14.3333 2.33333V10.3333C14.3333 11.0667 13.7333 11.6667 13 11.6667H2.33333C1.6 11.6667 1 11.0667 1 10.3333V2.33333C1 1.6 1.6 1 2.33333 1Z" />
    <path d="M14.3333 2.33325L7.66667 6.99992L1 2.33325" />
  </Icon>
);

// eslint-disable-next-line import/no-default-export
export default MailIcon;
